import { Stack, StackProps } from "@mui/material";
import { Room } from "../../../types/room";
import { RoomIdentity, RoomIdentityProps } from "./RoomIdentity";

export interface RoomListProps {
  rooms?: Room[];
  slotProps?: Partial<{
    root: Partial<StackProps>;
    item: Partial<RoomIdentityProps>;
  }>;
}
export const RoomList = ({ rooms = [], slotProps: { root, item } = {} }: RoomListProps) => (
  <Stack spacing={6} {...root}>
    {rooms.map((room) => (
      <RoomIdentity key={room.id} room={room} {...item} />
    ))}
  </Stack>
);
