import qs from "qs";
import { defineUrl, QueryParamsObject, UrlParseOptions, UrlUpdateOptions } from "url-and-query";

const appUrlDef = defineUrl(qs, {
  stringifyOptions: [{ encode: false, arrayFormat: "brackets", skipNulls: true }],
});

export const qsUrl = appUrlDef.stringify;

//Custom parsing with decodeURIComponent to handle some wierd encoded Urls from the backend
export const qpUrl = (url: string, options: Partial<UrlParseOptions<qs.IParseOptions>> = {}) =>
  appUrlDef.parse(decodeURIComponent(url), options);
export const updateQueryParams = (
  url: string,
  query: QueryParamsObject,
  options: Partial<UrlUpdateOptions<qs.IParseOptions>> = {},
) => appUrlDef.update(decodeURIComponent(url), query, options);
