import { QueryParamsObject } from "url-and-query";
import { qsUrl, query } from "../utils";
import { api } from "./api";
import { ApiResponse } from "./types";

const ROOT_KEY = ["common"];

export interface HookResponse {
  data: {
    id: number;
    title: string;
    url: string;
  };
}

const COMMON_ENDPOINTS = {
  GET_ROUTE_TEMPLATE: () => "/templates/getRoute",
  GET_HOOK_CONTENT: (hookId: number) => `/contentEditor/getHookContent/${hookId}`,
};

export const useCommonApi = () => {
  const getRouteTemplate = query(
    async (pathName: string) =>
      (
        await api.post<ApiResponse<any>>(
          qsUrl(COMMON_ENDPOINTS.GET_ROUTE_TEMPLATE(), { with: ["breadCrumbs", "templateData", "metaData"] }),
          {
            url: pathName,
          },
        )
      ).data.results,
    [...ROOT_KEY, "routeTemplate"],
  );

  const getHookContent = query(
    async (hookId: number, params: QueryParamsObject) =>
      (await api.get<ApiResponse<HookResponse[]>>(qsUrl(COMMON_ENDPOINTS.GET_HOOK_CONTENT(hookId), params))).data
        .results,
    [...ROOT_KEY, "hook"],
  );

  return {
    getRouteTemplate,
    getHookContent,
  };
};
