import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle } from "@mui/material";

interface InvalidCartModalProps extends DialogProps {
  onRemove?: () => void;
}
export const InvalidCartModal = ({ onRemove, ...props }: InvalidCartModalProps) => (
  <Dialog {...props}>
    <DialogTitle>Upozornění na obsazený pokoj</DialogTitle>
    <DialogContent>
      Ve vašem košíku se nalézá pokoj, který je v daném termínu již obsazený. Abyste mohli rezervaci dokončit, tak je
      potřeba pokoj z košíku odstranit.
    </DialogContent>
    <DialogActions>
      <Button autoFocus color="primary" variant="contained" onClick={onRemove}>
        Odstranit z košíku
      </Button>
    </DialogActions>
  </Dialog>
);
