import { Box, BoxProps, Link, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import { HookResponse, useCommonApi } from "../../api";
import { dynamicContentParentPath } from "../../paths";
import { FlexBlock } from "../common";

interface ContentLink {
  title: string;
  url: string;
}

const parseLinks = (links: HookResponse[]): ContentLink[] => links.map(({ data: { title, url } }) => ({ title, url }));

interface LegislativePanelContentProps extends BoxProps {
  links?: ContentLink[];
}
const LegislativePanelContent = ({ sx = {}, links = [], ...props }: LegislativePanelContentProps) => (
  <Box
    padding={2}
    borderRadius={2}
    sx={{
      border: ({ palette }) => `1px solid ${palette.divider}`,
      backgroundColor: ({ palette }) => `${palette.background.paper}`,
      ...sx,
    }}
    {...props}
  >
    <Stack direction="row" spacing={2} width="100%">
      {links.map(({ title, url }, idx) => (
        <Link component={RouterLink} key={idx} to={dynamicContentParentPath(url)} fontSize="small">
          {title}
        </Link>
      ))}
      <FlexBlock />
      <Box>
        <Typography fontSize="small">Vytvořila Geryla</Typography>
      </Box>
    </Stack>
  </Box>
);

export const LegislativePanel = (props: Omit<LegislativePanelContentProps, "links">) => {
  const { data: content = [], isLoading } = useQuery(useCommonApi().getHookContent(1, {}));

  return <LegislativePanelContent links={isLoading ? [] : parseLinks(content)} {...props} />;
};
