import { createContext, useContext, useState } from "react";
import { AppSettings, BookingSettings } from "../types/bookings";

export interface AppSettingsContextType {
  settings: AppSettings;
  update: (settings: Partial<BookingSettings>) => void;
}

const AppSettingsContext = createContext<AppSettingsContextType>({} as AppSettingsContextType);

export const useAppSettings = () => useContext(AppSettingsContext);

export const AppSettingsProvider = ({
  defaultSettings,
  children,
}: {
  defaultSettings: AppSettings;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState(defaultSettings);

  return (
    <AppSettingsContext.Provider
      value={{
        settings: state,
        // we should be able to update only booking settings nothing else for now
        update: (update) => setState((prev) => ({ ...prev, booking: { ...prev.booking, ...update } })),
      }}
    >
      {children}
    </AppSettingsContext.Provider>
  );
};
