import { Box, BoxProps, Icon } from "@mui/material";
import { ReactNode } from "react";

export interface ImageBoxProps extends BoxProps<"img"> {
  slotProps?: Partial<{
    container: Partial<BoxProps>;
  }>;
  overlay?: ReactNode;
}
export const ImageBox = ({ sx = {}, slotProps: { container } = {}, height, overlay, ...props }: ImageBoxProps) => (
  <Box
    display="block"
    position="relative"
    height={1}
    overflow="hidden"
    {...container}
    sx={{
      borderRadius: 2,
      flexShrink: 0,
      ...(container?.sx ?? {}),
    }}
  >
    <Box
      component="img"
      display="block"
      maxWidth={1}
      height="auto"
      {...props}
      sx={{
        objectFit: "cover",
        objectPosition: "center",
        transition: "transform .5s ease-out",
        position: "relative",
        width: "100%",
        height: "100%",
        "&:hover": {
          transform: "scale(1.03)",
          transformOrigin: "center",
        },
        cursor: "pointer",
        ...sx,
      }}
    />
    {/* {!overlay && (
      <Box
        position="absolute"
        bottom={20}
        left={20}
        width={35}
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={35}
        borderRadius={30}
        color="primary.contrastText"
        sx={{ opacity: 0.8, backgroundColor: ({ palette }) => `${palette.primary.main}` }}
      >
        <Icon>search</Icon>
      </Box>
    )} */}
    {overlay && (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ position: "absolute", inset: 0, backgroundColor: "rgba(228, 232, 237, 0.7)" }}
      >
        {overlay}
      </Box>
    )}
  </Box>
);
