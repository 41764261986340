import { Box, Stack, StackProps } from "@mui/material";
import { useEffect } from "react";
import { FlexBlock, Loading } from "../common";

export interface PageLayoutProps extends StackProps {
  header?: React.ReactNode;
  compact?: boolean;
  loading?: boolean;
  summaryPanel?: React.ReactNode;
  footer?: React.ReactNode;
  summaryPosition?: "top" | "bottom" | "top-bottom";
}
export const PageLayout = ({
  sx = {},
  header,
  compact,
  loading,
  summaryPanel,
  footer,
  children,
  summaryPosition,
  ...props
}: PageLayoutProps) => {
  useEffect(() => {
    const sendHeight = () => {
      const height = Math.max(document.body.scrollHeight);
      window.parent.postMessage({ type: "setHeight", height }, "*");
      console.log("changed height", height);
    };

    const observer = new MutationObserver(() => {
      sendHeight();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    sendHeight();

    window.addEventListener("resize", sendHeight);

    return () => {
      observer.disconnect();
      window.removeEventListener("resize", sendHeight);
    };
  }, []);

  return (
    <Stack
      spacing={4}
      {...props}
      sx={{ height: "100%", maxWidth: 1280, paddingY: 4, paddingX: 0, marginX: "auto", ...sx }}
    >
      {header}
      {compact ? (
        <>
          {(summaryPosition === "top" || summaryPosition == "top-bottom") && (
            <Stack direction="column" sx={{ top: 32, zIndex: 10, height: "fit-content" }}>
              {summaryPanel}
            </Stack>
          )}
          <Box>{loading ? <Loading /> : children}</Box>
          {summaryPosition === "bottom" && (
            <Stack direction="column" sx={{ top: 32, zIndex: 10, height: "fit-content" }}>
              {summaryPanel}
            </Stack>
          )}
          {footer}
          {/* fake box after the footer is here artificaly so that we can still see the content for the summaryPanel -> useMeasure would be a better approch for this */}
          <Box sx={{ height: 30 }} />
        </>
      ) : (
        <Stack direction="column" spacing={6}>
          <Stack direction="row" spacing={6}>
            <FlexBlock>{loading ? <Loading /> : children}</FlexBlock>
            {summaryPanel}
          </Stack>
          {footer}
        </Stack>
      )}
    </Stack>
  );
};
