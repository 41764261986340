import { Box, BoxProps, Stack } from "@mui/material";

import { useRoomBooking } from "../../../hooks";
import { Room } from "../../../types/room";
import { ImageBox } from "../../common/ImageBox";
import { RoomInfo } from "./RoomInfo";

export interface RoomIdentityProps extends BoxProps {
  room: Room;
  onReserve?: (roomId: Room["id"], room: Room) => void;
  onImageClick?: (room: Room) => void;
  compact?: boolean;
}
export const RoomIdentity = ({ room, onReserve, onImageClick, compact, sx, ...props }: RoomIdentityProps) => {
  const { bookings } = useRoomBooking();
  return (
    <Box padding={4} borderRadius={2} boxShadow={1} {...props} sx={{ backgroundColor: "background.paper", ...sx }}>
      <Stack direction={compact ? "column" : "row"} spacing={3}>
        {room.image && (
          <ImageBox
            src={room.image.url}
            alt={room.image.alt}
            // onClick={() => !compact && onImageClick?.(room)}
            sx={compact ? { width: "100%", objectFit: "cover", objectPosition: "center" } : {}}
            slotProps={{ container: { sx: compact ? { height: 250 } : { width: 400, height: 250 } } }}
            overlay={
              !room.available && (
                <Box typography="h6" fontWeight="medium" textAlign="center" padding={2}>
                  Pokoj není dostupný ve vybraném termínu
                </Box>
              )
            }
          />
        )}
        <RoomInfo
          room={room}
          compact={compact}
          onReserve={(roomId) => onReserve?.(roomId, room)}
          inCart={bookings.some(({ id }) => id === room.id)}
        />
      </Stack>
    </Box>
  );
};
