import Autocomplete, { AutocompleteProps } from "@mui/material/Autocomplete";
import Box, { BoxProps } from "@mui/material/Box";
import { countries, CountryType } from "../../data/countries";

interface CountryImageProps extends Omit<BoxProps<"img">, "src" | "srcSet"> {
  countryCode: string;
}
export const CountryImage = ({ countryCode, ...props }: CountryImageProps) => (
  <Box
    component="img"
    loading="lazy"
    width="20px"
    srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
    src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
    alt=""
    {...props}
  />
);

export interface CountrySelectProps
  extends Omit<AutocompleteProps<CountryType, false, true, false>, "options" | "renderOption"> {
  labelRender?: (country: CountryType) => string;
}
export const CountrySelect = ({
  labelRender = (option) => `${option.label} (${option.code}) +${option.phone}`,
  ...props
}: CountrySelectProps) => (
  <Autocomplete
    options={countries}
    autoHighlight
    disableClearable
    getOptionLabel={({ label }) => label}
    noOptionsText="Žádné možnosti"
    renderOption={(props, option) => (
      <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
        <CountryImage countryCode={option.code} />
        {labelRender(option)}
      </Box>
    )}
    {...props}
  />
);
