import { ModalProps } from "@mui/base";
import { Box, Modal } from "@mui/material";

import Carousel from "react-material-ui-carousel";
import { useRoomBooking } from "../../../hooks";
import { Room } from "../../../types/room";
import { RoomInfo } from "./RoomInfo";

interface RoomDetailModalProps extends Omit<ModalProps, "children"> {
  room: Room;
  compact?: boolean;
  onReserve?: (room: Room) => void;
}
export const RoomDetailModal = ({ room, compact, onReserve, ...props }: RoomDetailModalProps) => {
  const { bookings } = useRoomBooking();
  return (
    <Modal disableAutoFocus {...props}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: compact ? 0.9 : 650,
          height: compact ? 0.9 : "auto",
          maxHeight: "100vh",
          boxShadow: 0,
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <Carousel
          autoPlay={true}
          height={compact ? 200 : 400}
          indicators={true}
          navButtonsProps={{ style: { opacity: 1 } }}
        >
          {(room.images?.all ?? []).map(({ url, alt }, idx) => (
            <Box key={idx} component="img" src={url} alt={alt} sx={{ width: 1, height: 1, objectFit: "cover" }} />
          ))}
        </Carousel>
        <Box sx={{ padding: compact ? 2 : 4 }}>
          <RoomInfo
            room={room}
            showDescription
            compact
            spacing={compact ? 1 : 2}
            onReserve={() => onReserve?.(room)}
            inCart={bookings.some(({ id }) => id === room.id)}
          />
        </Box>
      </Box>
    </Modal>
  );
};
