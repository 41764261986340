import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, IconButtonProps, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export interface BackButtonProps extends Omit<IconButtonProps, "onClick"> {
  path?: string;
}
export const BackButton = ({ path, ...props }: BackButtonProps) => {
  const navigate = useNavigate();

  return (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={() => (path ? navigate(path) : navigate(-1))} {...props}>
        <ArrowBackIcon />
      </IconButton>
      Zpět
    </Stack>
  );
};
