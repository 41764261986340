import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useWhen = (condition: boolean, callback: () => void, deps: any[] = []) => {
  useEffect(() => {
    if (condition) {
      callback();
    }
  }, [condition, callback, ...deps]);
};

export const useNavigateWhen = (condition: boolean, to: string | undefined) => {
  const navigate = useNavigate();

  useWhen(
    condition,
    () => {
      if (to) {
        navigate(to);
      }
    },
    [to],
  );
};
