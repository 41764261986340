import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, BoxProps, Button, ButtonProps, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useBookingApi, useOrderApi } from "./api";
import { PaymentStatus } from "./api/types";
import { useInitialQueryParams, useNavigateWhen } from "./hooks";
import { PATHS } from "./paths";
import { CustomThemeProvider } from "./theme/CustomThemeProvider";
import { appParams, CustomTheme } from "./utils";

const ICON_SIZE = 140;
const PROGRESS_SIZE = ICON_SIZE * 0.8;

const BackHomeButton = (props: Omit<ButtonProps, "children">) => (
  <Button variant="contained" color="primary" size="large" startIcon={<ArrowBackIcon />} {...props}>
    Zpět na stránku hotelu
  </Button>
);

const PaymentConfirmationAppContainer = ({ sx = {}, ...props }: BoxProps<"main">) => (
  <Box
    component="main"
    padding={3}
    sx={{
      flexGrow: 1,
      bgcolor: "background.default",
      height: 1,
      width: 1,
      position: "relative",
      ...sx,
    }}
    {...props}
  />
);

const PaymentStatusIndicator = ({
  status,
  gatewayUrl,
  appUrl,
}: {
  status: PaymentStatus;
  gatewayUrl: string;
  appUrl?: string;
}) => (
  <Box padding={2}>
    <Stack spacing={6} alignItems="center">
      {(() => {
        switch (status) {
          case PaymentStatus.PENDING:
          case PaymentStatus.CREATED:
            return (
              <>
                <CircularProgress size={PROGRESS_SIZE} thickness={4} />
                <Typography variant="h3" typography="h5" textAlign="center">
                  Čekáme na platbu
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  onClick={() => window.location.replace(gatewayUrl)}
                >
                  Zaplatit
                </Button>
              </>
            );
          case PaymentStatus.FAILURE:
            return (
              <>
                <CancelIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} color="error" />
                <Typography variant="h3" typography="h5" textAlign="center">
                  Platba selhala
                </Typography>
                {appUrl && <BackHomeButton onClick={() => window.location.replace(appUrl)} />}
              </>
            );
          case PaymentStatus.SUCCESS:
            return (
              <>
                <CheckCircleIcon sx={{ width: ICON_SIZE, height: ICON_SIZE }} color="success" />
                <Typography variant="h3" typography="h5" textAlign="center">
                  Platba proběhla úspěšně
                </Typography>
                {appUrl && <BackHomeButton onClick={() => window.location.replace(appUrl)} />}
              </>
            );
          default:
            return null;
        }
      })()}
    </Stack>
  </Box>
);

export const PaymentConfirmationApp = () => {
  const { orderId, id, appid } = appParams(useInitialQueryParams());
  //NOTE: viewParam is not fully implemented yet, it doesnt affect the PaymentConfirmationPage so its not globally used

  const { mutate: checkOrderStatus, data } = useMutation(useOrderApi().getOrderStatus());
  const { data: appSettings, isLoading } = useQuery(useBookingApi().getAppSettings(appid));

  useNavigateWhen(!orderId, PATHS.HOME);

  useEffect(() => {
    checkOrderStatus({ bookingCode: orderId! });
  }, [orderId, id]);

  if (!data?.statusData) return null;

  const customTheme: CustomTheme = {
    primaryColor: appSettings?.common?.primaryColor,
  };

  const {
    statusData: { status },
    paymentInfo: { gatewayUrl },
  } = data;

  return (
    <CustomThemeProvider theme={customTheme}>
      <PaymentConfirmationAppContainer>
        <Stack spacing={4} sx={{ maxWidth: 600, margin: "auto" }} divider={<Divider />}>
          <Typography variant="h1" typography="h3" textAlign="center">
            Potvrzení platby
          </Typography>
          <Stack spacing={6} alignItems="center">
            {!isLoading && (
              <PaymentStatusIndicator status={status} gatewayUrl={gatewayUrl} appUrl={appSettings?.common.appUrl} />
            )}
          </Stack>
        </Stack>
      </PaymentConfirmationAppContainer>
    </CustomThemeProvider>
  );
};
