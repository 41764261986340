import { createTheme } from "@mui/material";
import { useMemo } from "react";
import { appTheme } from "../theme/appTheme";

export interface CustomTheme {
  primaryColor?: string;
}
export const useCustomTheme = ({ primaryColor }: CustomTheme) =>
  useMemo(
    () =>
      primaryColor
        ? createTheme({
            ...appTheme,
            palette: {
              primary: {
                main: primaryColor,
              },
            },
          })
        : {},
    [primaryColor],
  );
