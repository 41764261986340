import { Box, BoxProps } from "@mui/material";

export interface FlexBlockProps extends BoxProps {}
export const FlexBlock = ({ sx = {}, ...props }: FlexBlockProps) => (
  <Box
    {...props}
    sx={{
      minHeight: 0,
      minWidth: 0,
      flexShrink: 1,
      flexGrow: 1,
      flexBasis: 0,
    }}
  />
);
