import { mutation } from "../utils";
import { api } from "./api";
import { ApiResponse, OrderStatusReponse } from "./types";

interface OrderStatusPayload {
  bookingCode: string;
}

const ENDPOINTS = {
  GET_ORDER_STATUS: () => `/bookingSystem/bookingStatus`,
};

export const useOrderApi = () => {
  const getOrderStatus = () =>
    mutation(
      async (payload: OrderStatusPayload) =>
        (await api.post<ApiResponse<OrderStatusReponse>>(ENDPOINTS.GET_ORDER_STATUS(), payload)).data.results,
      {},
    );

  return {
    getOrderStatus,
  };
};
