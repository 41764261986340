import { createTheme, Shadows } from "@mui/material";

const customShadow = "0px 0px 2px rgba(0,0,0,0.2)";
const defaultTheme = createTheme();

export const appTheme = createTheme({
  palette: {
    background: {
      default: "transparent",
      paper: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    body0: {
      fontSize: "1.25rem",
      lineHeight: 1.333333333333333,
    },
  },
  //overrided level 1 shadow
  shadows: ["none", customShadow, ...defaultTheme.shadows.splice(2)] as Shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          padding: "10px 20px",
        },
      },
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.common.black,
          fontWeight: 600,
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.common.white,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // Resetting color for InputLabel to ensure it's not affected
          color: "inherit",
          fontWeight: "inherit",
        },
      },
    },
  },
});
