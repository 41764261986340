import { isEmpty, parseInt } from "lodash";
import { Attribute, ImageMetadata, RoomDto, RoomId } from "../api/types";

export enum MainAtributeKey {
  ROOM_SIZE = "roomSize",
  BED_COUNT = "bedsCount",
  BEDS_TYPE = "bedsType",
  ROOMS_COUNT = "roomsCount",
  FREE_WIFI = "freeWifi",
  AC = "airConditioning",
  SMOKING_ALLOWED = "smokingAllowed",
  PRIVATE_BATHROOM = "privateBathroom",
}

export enum FacilityKey {
  TELEVISION = "television",
  FLAT_SCREEN = "flatScreen",
  STREAMIN_PLATFORMS = "streamingPlatforms",
  MINI_BAR = "minibar",
  TOWELS = "towels",
  HAIRDRYER = "hairdryer",
  TELEPHONE = "telephone",
}

export interface FacilityAttribute extends Omit<Attribute<FacilityKey>, "value"> {
  value: string | number | boolean;
}
export interface MainAttribute extends Omit<Attribute<MainAtributeKey>, "value"> {
  value: string | number | boolean;
}

export interface ImageCategoriesDto {
  all: ImageMetadata[];
  other: ImageMetadata[];
}

export interface Booking {
  dateFrom: Date;
  dateTo: Date;
  occupancy: number;
}

export interface Room {
  id: RoomId;
  title: string;
  available: boolean;
  occupancyMin: number;
  occupancyMax: number;
  description?: string;
  facilities?: FacilityAttribute[];
  attributes?: MainAttribute[];
  image?: ImageMetadata;
  images?: ImageCategoriesDto;
  size?: number;
  totalPrice: number;
  unitPrice: number;
}

export interface RoomBooking extends Room {
  booking: Booking;
}

const findAttribute = (attributes: Attribute[]) => (attrKey: Attribute["key"]) =>
  attributes.find(({ key }) => key === attrKey);

export const parseAttributeValue = <T extends any = any>({ type, value }: Pick<Attribute<T>, "type" | "value">) => {
  switch (type) {
    case "Boolean":
      return value === "1";
    case "Integer":
      return parseInt(value);
    case "String":
    default:
      return value;
  }
};

export const parseRoom = ({
  id,
  title,
  images,
  occupancyMax,
  occupancyMin,
  attributes,
  description,
  facilities,
  prices,
  availability,
  unitPrice,
}: RoomDto): Room => ({
  id,
  title,
  occupancyMin,
  occupancyMax,
  description,
  available: availability.isAvailable,
  totalPrice: prices.price_value_tax,
  unitPrice: unitPrice?.price_value_tax ?? 0, // unit price is not always present
  facilities: facilities?.map(({ value, type, ...rest }) => ({
    ...rest,
    type,
    value: parseAttributeValue({ value, type }),
  })),
  attributes: attributes?.map(({ value, type, ...rest }) => ({
    ...rest,
    type,
    value: parseAttributeValue({ value, type }),
  })),
  size: attributes ? parseInt(findAttribute(attributes)(MainAtributeKey.ROOM_SIZE)?.value!) : undefined,
  image: !isEmpty(images) ? images.head : undefined,
  images: !isEmpty(images)
    ? {
        all: images.all,
        other: images.other,
      }
    : undefined,
});

export const parseRooms = (rooms: RoomDto[]): Room[] => rooms.map(parseRoom);
