import { isEmpty } from "lodash";
import { createContext, ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export interface InitialQueryParamsContextType extends Record<string, string> {}
export const InitialQueryParamsContext = createContext<InitialQueryParamsContextType | undefined>(undefined);

export interface InitialQueryParamsProviderProps {
  children: ReactElement;
}
export const InitialQueryParamsProvider = ({ children }: InitialQueryParamsProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialized = isEmpty(searchParams.entries());
  useEffect(() => {
    if (!initialized) setSearchParams({}, { replace: true });
  }, []);

  const initialParams = useMemo(() => {
    const params: any = {};
    for (const key of searchParams.keys()) {
      params[key] = searchParams.get(key);
    }
    return params;
  }, []);

  const [params, setParams] = useState(initialParams);
  const initialQueryParamsContext = useMemo(() => params, [params, setParams]);

  return (
    <InitialQueryParamsContext.Provider value={initialQueryParamsContext}>
      {initialized && children}
    </InitialQueryParamsContext.Provider>
  );
};

export const useInitialQueryParams = () => useContext(InitialQueryParamsContext) as InitialQueryParamsContextType;
