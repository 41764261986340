interface EnviromentVariables {
  IS_DEV: boolean;
  PROD_URL: string;
  SENTRY_DSN: string;
  API_URL: string;
}

export const env: EnviromentVariables = {
  IS_DEV: process.env.NODE_ENV === "development",
  PROD_URL: process.env.REACT_APP_PROD_URL!,
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN!,
  API_URL: process.env.REACT_APP_API_URL!,
};
