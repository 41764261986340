import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import { Box, Divider, IconButton, Paper, PaperProps, Stack, Typography } from "@mui/material";
import { FormattedPlural } from "react-intl";
import { BookingSettings } from "../../types/bookings";
import { Room } from "../../types/room";
import { FlexBlock, FormattedCurrency } from "../common";

export const PANEL_SIZE = 325;

export const SummaryPanelStickyContainer = ({ sx = {}, children, ...props }: PaperProps) => (
  <Paper
    id="summary-panel"
    sx={{
      minWidth: PANEL_SIZE,
      width: PANEL_SIZE,
      padding: 2,
      height: "fit-content",
      borderRadius: 2,
      position: "sticky",

      top: 32,
      ...sx,
    }}
    {...props}
  >
    {children}
  </Paper>
);

export const PriceSummary = ({ totalPrice }: { totalPrice: number }) => (
  <Box component="span">
    <Typography variant="body1" color="primary">
      Cena celkem
    </Typography>
    <Typography variant="body0" fontWeight="bold">
      <FormattedCurrency value={totalPrice} maximumFractionDigits={2} />
    </Typography>
    <Typography variant="body2" color="text.secondary">
      Cena zahrnuje DPH
    </Typography>
  </Box>
);

export interface SummaryPanelProps {
  rooms: Room[];
  totalPrice: number;
  bookingsInfo: BookingSettings;
  onRemoveAll?: () => void;
  onRemove?: (id: Room["id"]) => void;
  action?: React.ReactNode;
  layout?: "rightPanel" | "footer";
}
export const SummaryPanel = ({
  rooms,
  bookingsInfo,
  totalPrice,
  action,
  layout = "rightPanel",
  onRemove,
  onRemoveAll,
}: SummaryPanelProps) =>
  layout === "rightPanel" ? (
    <Stack spacing={2}>
      <Box>
        <Typography variant="h6" color="primary">
          {" "}
          Vaše rezervace{" "}
        </Typography>
      </Box>

      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        width="100%"
        alignItems="start"
        divider={<Divider />}
      >
        {bookingsInfo.dateFrom && (
          <Box>
            <Typography fontSize="small">Datum příjezdu</Typography>
            {bookingsInfo.dateFrom.toLocaleDateString()}
          </Box>
        )}
        {bookingsInfo.dateTo && (
          <Box>
            <Typography fontSize="small">Datum odjezdu</Typography>
            {bookingsInfo.dateTo.toLocaleDateString()}
          </Box>
        )}
      </Stack>

      <Stack spacing={1} divider={<Divider />}>
        {rooms.map((room, idx) => (
          <Stack key={idx}>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" height={30}>
              <Typography typography="body2" fontWeight="bold">
                {room.title}
              </Typography>
              {onRemove && (
                <Box display="flex" alignItems="center" flexShrink={0}>
                  <IconButton size="small" color="primary" onClick={() => onRemove(room.id)}>
                    <CancelOutlinedIcon fontSize="small" />
                  </IconButton>
                </Box>
              )}
            </Stack>
            <Box display="flex" justifyContent="space-between" alignItems="center" typography="body2">
              <Typography>
                {room.occupancyMax > 1 ? `až ${room.occupancyMax}` : room.occupancyMax}{" "}
                <FormattedPlural value={room.occupancyMax} one="osoba" few="osoby" other="osob" />
              </Typography>
              <Typography fontWeight="bold">
                <FormattedCurrency value={room.totalPrice} maximumFractionDigits={2} />
              </Typography>
            </Box>
          </Stack>
        ))}
        <PriceSummary totalPrice={totalPrice} />
        <Typography fontSize="small" fontWeight={500} color="primary">
          - Při dlouhodobém ubytování individuální ceny.
        </Typography>
        {action}
      </Stack>
    </Stack>
  ) : (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" spacing={2} alignItems="center">
        <FlexBlock>
          <PriceSummary totalPrice={totalPrice} />
        </FlexBlock>
        <Stack direction="row" alignItems="center" spacing={2}>
          {onRemoveAll && (
            <IconButton onClick={onRemoveAll}>
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          )}
          {action}
        </Stack>
      </Stack>
      <Typography fontSize="small" fontWeight={500} color="primary">
        - Při dlouhodobém ubytování individuální ceny.
      </Typography>
    </Stack>
  );
