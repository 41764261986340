import { Box, BoxProps, SvgIconProps } from "@mui/material";
import { FC } from "react";

export const LabeledIconContainer = (props: BoxProps) => (
  <Box component="span" display="flex" alignItems="center" gap="0.25em" {...props} />
);

export interface LabeledIconProps extends BoxProps {
  Icon: FC<SvgIconProps>;
}
export const LabeledIcon = ({ Icon, children, sx = {}, ...props }: LabeledIconProps) => (
  <LabeledIconContainer color="text.secondary" {...props} sx={{ userSelect: "none", ...sx }}>
    <Icon fontSize="small" color="primary" />
    {children}
  </LabeledIconContainer>
);
