import { Paper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { has } from "lodash";
import { useParams } from "react-router-dom";
import { useCommonApi } from "../../api";
import { useNavigateWhen } from "../../hooks";
import { PATHS } from "../../paths";
import { BackButton } from "../common";
import { LegislativePanel } from "./LegislativePanel";
import { PageLayout } from "./PageLayout";

interface DynamicPageProps {
  compact?: boolean;
}
interface DynamicPageContentProps extends DynamicPageProps {
  pageId: string;
}
export const DynamicPageContent = ({ pageId, compact }: DynamicPageContentProps) => {
  const { data, isLoading } = useQuery(useCommonApi().getRouteTemplate(`/${pageId}`));

  // Redirect to home page if page not found ¯\_(ツ)_/¯
  useNavigateWhen(Boolean(data?.code === 404), PATHS.HOME);

  return (
    <PageLayout header={<BackButton />} loading={isLoading} compact={compact} footer={<LegislativePanel />}>
      {has(data, "templateData.description") && (
        <Paper
          sx={{ padding: 4 }}
          dangerouslySetInnerHTML={{
            __html: data.templateData.description,
          }}
        />
      )}
    </PageLayout>
  );
};

export const DynamicPage = (props: DynamicPageProps) => {
  let { pageId } = useParams<{ pageId: string }>();
  if (!pageId) return null;
  return <DynamicPageContent pageId={pageId} {...props} />;
};
