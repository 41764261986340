//dynamic content has /content prefix to distinguish from other routes
//when using dynamic content, use dynamicContentParentPath to get the correct path
export const dynamicContentParentPath = (path: string) => `/content${path.startsWith("/") ? "" : "/"}${path}`;

export const PATHS = {
  HOME: "/",
  BOOK: "/book",
  PURCHASE: "/purchase",
  PAYMENT_CONFIRMATION: "/payment-confirmation",
  DYNAMIC_CONTENT: dynamicContentParentPath(":pageId"),
};
