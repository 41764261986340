import CropOutlinedIcon from "@mui/icons-material/CropOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Box, BoxProps, Button, Divider, Icon, Stack, StackProps, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import { FormattedPlural } from "react-intl";
import { FacilityAttribute, Room } from "../../../types/room";
import { FormattedCurrency, LabeledIcon, LabeledIconContainer } from "../../common";

const RoomBaseAttributes = ({ maxOccupancy, size }: { maxOccupancy: Room["occupancyMax"]; size?: Room["size"] }) => (
  <Stack spacing={4} direction="row">
    <LabeledIcon Icon={PersonOutlineOutlinedIcon}>
      <Typography>
        {maxOccupancy > 1 ? `až ${maxOccupancy}` : maxOccupancy}{" "}
        <FormattedPlural value={maxOccupancy} one="osoba" few="osoby" other="osob" />
      </Typography>
    </LabeledIcon>
    {size && (
      <LabeledIcon Icon={CropOutlinedIcon}>
        <Typography>
          {size} m<sup>2</sup>
        </Typography>
      </LabeledIcon>
    )}
  </Stack>
);

const RoomInfoTitle = ({ title }: { title: string }) => (
  <Typography variant="h5" fontWeight="bold">
    {title}
  </Typography>
);

interface RoomInfoFacilitiesListProps extends StackProps {
  facilities: FacilityAttribute[];
}
const RoomInfoFacilitiesList = ({ facilities, ...props }: RoomInfoFacilitiesListProps) => (
  <Stack spacing={0.5} typography="body2" {...props}>
    {facilities
      .filter(({ value }) => value)
      .map(({ locale, key }) => (
        <LabeledIconContainer key={key} gap={2}>
          <Icon fontSize="small">check</Icon>
          {locale}
        </LabeledIconContainer>
      ))}
  </Stack>
);

interface RoomReservationProps extends BoxProps {
  room: Pick<Room, "id" | "unitPrice">;
  onReserve?: (roomId: Room["id"]) => void;
  disabled?: boolean;
}
const RoomReservation = ({ room: { id, unitPrice }, onReserve, disabled, ...props }: RoomReservationProps) => (
  <Box
    display="flex"
    flexDirection="row"
    gap={1}
    justifyContent="space-between"
    alignItems="flex-end"
    width="100%"
    textAlign="right"
    {...props}
  >
    <Box component="span" textAlign="left">
      <Typography variant="body2" color="text.secondary">
        Cena za pokoj/noc
      </Typography>
      <Typography variant="body0" fontWeight={600}>
        <FormattedCurrency value={unitPrice} maximumFractionDigits={2} />
      </Typography>
    </Box>
    <Button variant="contained" onClick={() => onReserve?.(id)} disabled={disabled}>
      Vybrat pokoj
    </Button>
  </Box>
);

interface RoomInfoContainerProps extends Omit<StackProps, "children"> {
  room: Room;
  children: (props: Room) => ReactNode;
}

const RoomInfoContainer = ({ children, room, ...props }: RoomInfoContainerProps) => (
  <Stack spacing={2} divider={<Divider />} {...props}>
    {children(room)}
  </Stack>
);

const RoomInfoDescription = ({ description }: { description: string }) => (
  <Box
    component="div"
    sx={{ overflowY: "auto", maxHeight: 190 }}
    dangerouslySetInnerHTML={{
      __html: description,
    }}
  />
);

interface RoomInfoProps extends Omit<StackProps, "children"> {
  room: Room;
  showDescription?: boolean;
  compact?: boolean;
  onReserve?: RoomReservationProps["onReserve"];
  inCart?: boolean;
}
const RoomInfoDefault = ({ room, showDescription, onReserve, compact, inCart, ...props }: RoomInfoProps) => (
  <RoomInfoContainer room={room} width={1} {...props}>
    {({ id, unitPrice, title, size, occupancyMax, facilities, description, available }) => [
      <React.Fragment key="header">
        <RoomInfoTitle title={title} />
        <RoomBaseAttributes size={size} maxOccupancy={occupancyMax} />
      </React.Fragment>,
      showDescription && description && <RoomInfoDescription key="description" description={description} />,
      compact
        ? [
            facilities && <RoomInfoFacilitiesList key="facilities" facilities={facilities} />,
            available && onReserve && (
              <RoomReservation key="action" room={{ id, unitPrice }} onReserve={onReserve} disabled={inCart} />
            ),
          ]
        : [
            <Box key="facilities-and-action" display="flex" flexDirection="column" gap={4} alignItems="flex-end">
              {facilities && <RoomInfoFacilitiesList facilities={facilities} sx={{ minWidth: "100%" }} />}

              {available && onReserve && (
                <RoomReservation key="action" room={{ id, unitPrice }} onReserve={onReserve} disabled={inCart} />
              )}
            </Box>,
          ],
    ]}
  </RoomInfoContainer>
);

export const RoomInfo = Object.assign(RoomInfoDefault, {
  Container: RoomInfoContainer,
  Title: RoomInfoTitle,
  BaseAttributes: RoomBaseAttributes,
  Description: RoomInfoDescription,
  FacilitiesList: RoomInfoFacilitiesList,
  Action: RoomReservation,
});
