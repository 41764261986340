import { ThemeProvider } from "@mui/material";
import { CustomTheme, mergeThemes, useCustomTheme } from "../utils";
import { appTheme } from "./appTheme";

export interface CustomThemeProviderProps {
  theme?: CustomTheme;
  children: React.ReactNode;
}
export const CustomThemeProvider = ({ theme, children }: CustomThemeProviderProps) => {
  const customTheme = useCustomTheme(theme ?? {});

  return <ThemeProvider theme={mergeThemes(appTheme, customTheme)}>{children}</ThemeProvider>;
};
