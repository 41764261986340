import { sumBy } from "lodash";
import { createContext, useContext, useMemo, useState } from "react";
import { Room } from "../types/room";

export interface RoomBookingContextType {
  bookings: Room[];
  totalPrice: number;
  addRoom: (room: Room) => void;
  removeRoom: (roomId: Room["id"][]) => void;
  clear: () => void;
}

export const RoomBookingContext = createContext<RoomBookingContextType>({} as RoomBookingContextType);

export const useRoomBooking = () => useContext(RoomBookingContext);

export const RoomBookingProvider = ({ children }: { children: React.ReactNode }) => {
  const [bookings, setBookings] = useState<Room[]>([]);

  const totalPrice = useMemo(() => sumBy(bookings, (room) => room.totalPrice), [bookings]);

  const addRoom = (room: Room) => {
    if (bookings.some(({ id }) => id === room.id)) {
      throw new Error("Room is already in the cart");
    }
    setBookings([...bookings, room]);
  };
  const removeRoom = (roomIds: Room["id"][]) => {
    setBookings([...bookings.filter(({ id }) => !roomIds.includes(id))]);
  };

  return (
    <RoomBookingContext.Provider
      value={{
        bookings,
        totalPrice,
        addRoom,
        removeRoom,
        clear: () => setBookings([]),
      }}
    >
      {children}
    </RoomBookingContext.Provider>
  );
};
