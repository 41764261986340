import {
  InfiniteData,
  InfiniteQueryObserverOptions,
  MutationOptions,
  QueryFunctionContext,
  QueryObserverOptions,
  QueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";

export type QueryKey = string[];

export type QueryDefinition<Args extends any[], Value> = (...args: Args) => Omit<
  QueryOptions<Value>,
  "queryKey" | "queryFn"
> & {
  queryKey: QueryKey;
  queryFn: () => Promise<Value>;
};

export type InfiniteQueryDefinition<Args extends any[], Value> = (...args: Args) => Omit<
  UseInfiniteQueryOptions<Value, unknown, InfiniteData<Value>>,
  "queryKey" | "queryFn"
> & {
  queryKey: QueryKey;
  queryFn: (context: QueryFunctionContext<QueryKey, number>) => Promise<Value>;
};

export const query =
  <Args extends any[], Result>(
    queryFn: (...args: Args) => Promise<Result>,
    queryKey: QueryKey,
    options: Omit<QueryObserverOptions<Result>, "queryFn" | "queryKey"> = {},
  ): QueryDefinition<Args, Result> =>
  (...args: Args) => ({
    ...options,
    queryKey: [...queryKey, ...args],
    queryFn: () => queryFn(...args),
  });

export const mutation = <Arg, Result = unknown, Context = unknown>(
  mutationFn: (variables: Arg) => Promise<Result>,
  options: Omit<MutationOptions<Result, unknown, Arg, Context>, "mutationFn">,
): MutationOptions<Result, unknown, Arg, Context> => ({
  mutationFn,
  ...options,
});

export const infiniteQuery =
  <Args extends any[], Result>(
    queryFn: (context: QueryFunctionContext<QueryKey, number>, ...args: Args) => Promise<Result>,
    queryKey: QueryKey,
    options: Omit<InfiniteQueryObserverOptions<Result, unknown, InfiniteData<Result>>, "queryFn" | "queryKey">,
  ): InfiniteQueryDefinition<Args, Result> =>
  (...args: Args) => ({
    ...options,
    queryKey: [...queryKey, ...args],
    queryFn: (context) => queryFn(context, ...args),
  });
