import { Box, BoxProps, CircularProgress } from "@mui/material";

export interface LoadingProps extends Omit<BoxProps, "children"> {}
export const Loading = ({ sx = {}, ...props }: LoadingProps) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      height: 1,
      width: 1,
      justifyContent: "center",
      alignItems: "center",
      ...sx,
    }}
    {...props}
  >
    <CircularProgress color="primary" />
  </Box>
);
