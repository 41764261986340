import { ThemeProvider } from "@emotion/react";
import { CssBaseline, IconButton } from "@mui/material";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider, useSnackbar } from "notistack";
import React from "react";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route } from "react-router-dom";
import { InitialQueryParamsProvider } from "./hooks";
import "./index.css";
import { PATHS } from "./paths";
import { PaymentConfirmationApp } from "./PaymentConfirmationApp";
import { ReservationApp } from "./ReservationApp";
import { sentryInit, SentryRoutes } from "./sentry";
import { appTheme } from "./theme/appTheme";

sentryInit();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
});

const SnackbarCloseButton = ({ snackbarKey }: { snackbarKey: any }) => {
  const { closeSnackbar } = useSnackbar();
  return <IconButton onClick={() => closeSnackbar(snackbarKey)}>X</IconButton>;
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <IntlProvider locale="cs">
      <ThemeProvider theme={appTheme}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline enableColorScheme />
          <SnackbarProvider
            maxSnack={2}
            action={(key) => <SnackbarCloseButton snackbarKey={key} />}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <BrowserRouter>
              <InitialQueryParamsProvider>
                <Sentry.ErrorBoundary>
                  <SentryRoutes>
                    <Route path="/*" element={<ReservationApp />} />
                    <Route path={PATHS.PAYMENT_CONFIRMATION} element={<PaymentConfirmationApp />} />
                  </SentryRoutes>
                </Sentry.ErrorBoundary>
              </InitialQueryParamsProvider>
            </BrowserRouter>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </IntlProvider>
  </React.StrictMode>,
);
