import { omit } from "lodash";
import { createContext, useContext, useState } from "react";
import { PurchaseFormData } from "../types/bookings";

export interface UserFormType {
  userDetail: Partial<PurchaseFormData>;
  setUserDetail: (userDetail: Partial<PurchaseFormData>) => void;
  clear: () => void;
}

export const UserForm = createContext<UserFormType>({} as UserFormType);

export const useUserForm = () => useContext(UserForm);

export const UserFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [userDetail, setUserDetail] = useState<Partial<PurchaseFormData>>({});

  return (
    <UserForm.Provider
      value={{
        userDetail,
        //Make sure that reservationConditions are not included in the userDetail e.g. always accepted right before the purchase
        setUserDetail: (change) => setUserDetail(omit(change, "reservationConditions")),
        clear: () => setUserDetail({}),
      }}
    >
      {children}
    </UserForm.Provider>
  );
};
