import { Button } from "@mui/material";
import { isEmpty } from "lodash";
import { Room } from "../../../types/room";
import { SummaryPanel, SummaryPanelProps } from "../SummaryPanel";

export interface BookingSummaryPanelProps extends Omit<SummaryPanelProps, "action"> {
  onReservationConfirm?: (rooms: Room[]) => void;
}
export const BookingSummaryPanel = ({ onReservationConfirm, rooms, ...props }: BookingSummaryPanelProps) => (
  <SummaryPanel
    {...props}
    rooms={rooms}
    action={
      onReservationConfirm && (
        <Button
          variant="contained"
          size="large"
          color="primary"
          fullWidth
          disabled={isEmpty(rooms)}
          onClick={() => onReservationConfirm?.(rooms)}
        >
          {props.layout === "footer" ? "Rezervovat" : "K potvrzení rezervace"}
        </Button>
      )
    }
  />
);
