import { parseAppSettings } from "../types/bookings";
import { parseRoom, parseRooms } from "../types/room";
import { mutation, query } from "../utils";
import { api } from "./api";
import {
  ApiResponse,
  AppSettingsDto,
  BookingBaseDto,
  BookingCreatedResponse,
  BookingValidationResponse,
  HotelId,
  PaymentMethodType,
  RoomDto,
  RoomId,
  SettingsDto,
} from "./types";

const ROOT_KEY = ["hotel"];

export interface BookingCreateData {
  settings: SettingsDto;
  bookings: BookingBaseDto[];
  contactDetails: { name: string; surname: string; email: string; phone: string };
  invoiceDetails: {
    name: string;
    surname: string;
    street: string;
    city: string;
    zip: string;
    country: string;
    companyName?: string;
    identificationNumber?: string;
    taxNumber?: string;
  };
  paymentMethod: PaymentMethodType;
  message?: string;
}
export interface BookingValidationData {
  bookings: BookingBaseDto[];
  settings: SettingsDto;
  prices: {
    totalPrice?: number;
    totalPriceTax: number;
  };
}

const ENDPOINTS = {
  GET_ROOM: (roomId: RoomId) => `/bookingSystem/getUnit/${roomId}`,
  GET_ROOMS: () => `/bookingSystem/loadAvailabilities`,
  GET_ROOM_AVAILABILITY: () => `/bookingSystem/checkAvailability`,
  CREATE_BOOKING: () => `/bookingSystem/createBooking`,
  VALIDATE_BOOKING: () => `/bookingSystem/validateBooking`,
  GET_APP_SETTINGS: (hotelId: HotelId) => `/bookingSystem/getAppSettings/${hotelId}`,
};

export const useBookingApi = () => {
  const getAppSettings = query(
    async (hotelId: HotelId) =>
      parseAppSettings((await api.get<ApiResponse<AppSettingsDto>>(ENDPOINTS.GET_APP_SETTINGS(hotelId))).data.results),
    [...ROOT_KEY],
  );

  const getRoom = query(
    async (roomId: RoomId) =>
      parseRoom((await api.get<ApiResponse<RoomDto>>(ENDPOINTS.GET_ROOM(roomId))).data.results!),
    [...ROOT_KEY, "room"],
  );

  const getRooms = query(
    async (hotelId: HotelId, startDate: Date, endDate: Date) =>
      parseRooms(
        (
          await api.post<ApiResponse<RoomDto[]>>(ENDPOINTS.GET_ROOMS(), {
            objectId: hotelId,
            startDate,
            endDate,
          })
        ).data.results,
      ),
    [...ROOT_KEY, "rooms"],
  );

  const checkAvailability = query(
    async (hotelId: HotelId, roomId: RoomId, startDate: Date, endDate: Date) =>
      parseRooms(
        (
          await api.post<ApiResponse<any>>(ENDPOINTS.GET_ROOM_AVAILABILITY(), {
            objectId: hotelId,
            unitId: roomId,
            startDate,
            endDate,
          })
        ).data.results,
      ),
    [...ROOT_KEY, "room", "availability"],
  );

  const validateCart = query(
    async (payload: BookingValidationData) =>
      (await api.post<ApiResponse<BookingValidationResponse>>(ENDPOINTS.VALIDATE_BOOKING(), payload)).data.results,
    [...ROOT_KEY, "bookings", "validation"],
  );

  const createBooking = () =>
    mutation(
      async (payload: BookingCreateData) =>
        (await api.post<ApiResponse<BookingCreatedResponse>>(ENDPOINTS.CREATE_BOOKING(), payload)).data.results,
      {},
    );

  return {
    getAppSettings,
    getRoom,
    getRooms,
    checkAvailability,
    createBooking,
    validateCart,
  };
};
